import React from "react";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import TableVejaMais from "../table/TableVejaMais";

const RowVejaMais = (props) => {
    if (props.carregando) {
        return 'Carregando'
    }

    if (props.isVerMais) {
        return (<TableVejaMais disparos={props.disparos}/>)
    }
}

RowVejaMais.propTypes = {
    aid: PropTypes.number,
    isVerMais: PropTypes.bool,
    handleIsVerMais: PropTypes.func,
}
export default (withTranslation()(RowVejaMais));