import {withTranslation} from "react-i18next";
import {Badge, Row} from "reactstrap";
import {MDBDataTable} from "mdbreact";
import React from "react";
import PropTypes from "prop-types";

const renderStatus = (disparo) => {
    if (disparo.enviada) {
        return <Badge className="rounded-pill bg-success">
            ENVIADO
        </Badge>
    }

    if (disparo.erro) {
        return <Badge className="rounded-pill bg-danger">
            FALHA
        </Badge>
    }
}

const TableVejaMais = (props) => {
    const data = {
        columns: [
            {
                label: "Grupo",
                field: "grupo",
                sort: "asc",
                responsive: true,
                width: 20,
                maxWidth: 20,
            },
            {
                label: "Data Envio",
                field: "dataEnvio",
                sort: "asc",
                responsive: true,
                width: 270,
            },
            {
                label: "Situação",
                field: "situacao",
                sort: "asc",
                responsive: true,
                width: 200,
            },
            {
                label: "Data Enviada",
                field: "dataEnviada",
                responsive: true,
                maxWidth: 100,
                center: true,
            },
            {
                label: "Duração",
                field: "duracaoEnvio",
                responsive: true,
                maxWidth: 100,
                center: true,
            }
        ],
        rows: props.disparos.map(disparo => ({
            ...disparo,
            situacao: renderStatus(disparo),
            dataEnviada: disparo.dataEnviada ? "" : new Date(disparo.dataEnviada).toLocaleString(),
            dataEnvio: new Date(disparo.dataEnvio).toLocaleString(),
            duracaoEnvio: Math.floor(disparo.duracaoEnvio) + 's'
        })),
    };
    return (
        <Row>
            <MDBDataTable
                className={"datatable-buttons_wrapper"}
                responsive
                striped
                bordered
                data={data}
                searchLabel={props.t("Search")}
                paginationLabel={[props.t("Previous"), props.t("Next")]}
                entriesLabel={props.t("Entries Label")}
                infoLabel={[
                    props.t("Showing"),
                    props.t("To"),
                    props.t("Of"),
                    props.t("Entries"),
                ]}
                noRecordsFoundLabel={props.t("No Records Found")}
                noBottomColumns
                exportToCSV={true}
                btn
                id="datatable"
            />
        </Row>
    )
}

TableVejaMais.propTypes = {
    t: PropTypes.any,
    disparos: PropTypes.array,
}

export default withTranslation()(TableVejaMais);