import {Badge, Card, CardBody, CardTitle, Col, Row,} from "reactstrap";
import {bindActionCreators} from "redux";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {MDBBtn} from "mdbreact";
import * as AgendamentosActions from "../../../../../store/agendamento/actions";
import * as MensagensActions from "../../../../../store/mensagens/actions";
import {formattedDate} from "../../../../../util/date";
import {
    TIPO_ADD_ADMINISTRADORES,
    TIPO_ADD_CONTATOS,
    TIPO_DESCRICAO_GRUPO,
    TIPO_IMAGEM_GRUPO,
    TIPO_MENSAGEM,
    TIPO_NOME_GRUPO,
    TIPO_REMOVER_CONTATO
} from "../validation/tipoOptions";
import RowVejaMais from "./RowVejaMais";
import {getDisparos} from "../../../../../api/zaphook/agendamento";

const CardAgendamentoDescription = (props) => {
    const {agendamento, mensagensState} = props;
    const [isVerMais, setIsVerMais] = useState(false)
    const [disparos, setDisparos] = useState([])
    const [carregando, setCarregando] = useState(false)
    const handleVerMais = () => {
        setCarregando(true)
        if (isVerMais === false) {
            getDisparos(agendamento.id).then(res => {
                setDisparos(res)
                setCarregando(false)
                setIsVerMais(!isVerMais)
            })
        } else {
            setIsVerMais(!isVerMais)
            setCarregando(false)
        }
    }
    
    const handlePreview = () => {
        const mensagem = mensagensState.mensagens.find((mensagem) => mensagem.id === agendamento.idMensagem)
        props.editarMensagem(mensagem)
    }

    function handleDelete() {
        props.deleteAgendamento(agendamento.id)
    }

    const handleEdit = () => {
        props.handleGroupClickEdit(agendamento)
        props.editarCliqueGrupo()
    }
    const renderStatus = () => {
        if (agendamento.enviada) {
            return <Badge className="rounded-pill bg-success">
                ENVIADO
            </Badge>
        }

        if (!agendamento.enviada && agendamento.dataEnviada !== null) {
            return <Badge className="rounded-pill bg-danger">
                FALHA
            </Badge>
        }

        if (agendamento.cancelada) {
            return <Badge className="rounded-pill bg-danger">
                CANCELADO
            </Badge>
        }

        return <Badge className="rounded-pill bg-primary">
            AGENDADO
        </Badge>
    }

    const renderTipo = () => {
        switch (agendamento.tipo) {
            case TIPO_MENSAGEM:
                return "Mensagem"
            case TIPO_IMAGEM_GRUPO:
                return "Troca de Foto"
            case TIPO_NOME_GRUPO:
                return "Troca de Nome"
            case TIPO_DESCRICAO_GRUPO:
                return "Troca de Descrição"
            case TIPO_ADD_ADMINISTRADORES:
                return "Adicionar Administradores"
            case TIPO_ADD_CONTATOS:
                return "Adicionar Contatos"
            case TIPO_REMOVER_CONTATO:
                return "Remover Contatos"
        }
    }
    useEffect(() => {
        props.getMensagens()
    }, []);

    if (!agendamento) {
        return null
    }

    return (
        <Col xl={12}>
            <Card className="rounded-pill">
                <Row className="g-0 align-items-center">
                    <Col md={12}>
                        <CardBody>
                            <Row className="flex">
                                <Col md={2} className="d-flex align-items-center justify-content-center">
                                    <CardTitle>Título</CardTitle>
                                </Col>
                                <Col md={2} className="d-flex align-items-center justify-content-center">
                                    <CardTitle>{renderTipo()}</CardTitle>
                                </Col>
                                <Col md={2} className="d-flex align-items-center justify-content-center">
                                    <CardTitle>{formattedDate(new Date(agendamento.dataCriada))}</CardTitle>
                                </Col>
                                <Col md={2} className="d-flex align-items-center justify-content-center">
                                    <CardTitle>{formattedDate(new Date(agendamento.dataEnvio))}</CardTitle>
                                </Col>
                                <Col md={2} className="d-flex align-items-center justify-content-center">
                                    <CardTitle>{renderStatus()}</CardTitle>
                                </Col>
                                <Col
                                    md={1}
                                    className="d-flex align-items-center justify-content-center flex-column"
                                >
                                    {agendamento.tipo === TIPO_MENSAGEM &&
                                        <MDBBtn size="sm" className="m-1" color="success" style={{minWidth: '107px'}}
                                                onClick={handlePreview}>
                                            Prévia
                                        </MDBBtn>
                                    }
                                    <MDBBtn size="sm" className="m-1" color="danger" onClick={handleDelete}
                                            disabled={agendamento.enviada}>
                                        Cancelar Agendamento
                                    </MDBBtn>
                                    {agendamento.enviada && (
                                        <MDBBtn size="sm" className="m-1" color="success" style={{minWidth: '107px'}}
                                                onClick={handleVerMais}>
                                            {isVerMais ? "Ver Menos" : "Disparos"}
                                        </MDBBtn>
                                    )}
                                </Col>
                            </Row>

                        </CardBody>
                    </Col>
                </Row>
            </Card>
            {agendamento.enviada && (
                <RowVejaMais disparos={disparos} carregando={carregando} isVerMais={isVerMais}/>)}
        </Col>
    );
};

const mapStatetoProps = (state) => ({
    agendamentosState: state.agendamentos,
    campanhasState: state.campanhas,
    mensagensState: state.mensagens
});
const mapDispatchtoProps = (dispatch) =>
    bindActionCreators({...AgendamentosActions, ...MensagensActions}, dispatch);

CardAgendamentoDescription.propTypes = {
    t: PropTypes.any,
    agendamento: PropTypes.object,
    mensagensState: PropTypes.object,
    editarMensagem: PropTypes.func,
    getMensagens: PropTypes.func,
    deleteAgendamento: PropTypes.func,
    editarCliqueGrupo: PropTypes.func,
};
export default connect(
    mapStatetoProps,
    mapDispatchtoProps,
)(withTranslation()(CardAgendamentoDescription));
