import {Paper} from "@mui/material";
import {DataGrid} from '@mui/x-data-grid';
import {withTranslation} from "react-i18next";
import {any, array, func} from "prop-types";
import logoZapEase from "../../../../../../../assets/images/logo-sm-dark.png";
import React, {useState} from "react";

const paginationModel = {page: 0, pageSize: 20};
const GruposTableMaterial = (props) => {
    const columns = [
        {field: 'foto', headerName: 'Foto', width: 70, sortable: false, renderCell: (params) => <img className="rounded-circle avatar-sm m-auto" src={params.value} />},
        {field: 'titulo', headerName: 'Título', width: 500 },
    ];
    const rows = props.data.map(row => (
        {
            id: row.id,
            foto: row.pictureUrl !== null ? row.pictureUrl : logoZapEase,
            titulo: row.subject
        }))

    return (
        <Paper sx={{height: 400, width: '100%'}}>
            <DataGrid
                rows={rows}
                columns={columns}
                initialState={{pagination: {paginationModel}}}
                pageSizeOptions={[5, 10]}
                checkboxSelection
                sx={{border: 0}}
                onRowSelectionModelChange={(selectedRows) => {
                    props.handleSelectRows(selectedRows);
                }}
                rowSelectionModel={props.selectedRows}
            />
        </Paper>
    );
}

GruposTableMaterial.propTypes = {
    data: array,
    selectedRows: array,
    handleSelectRows: func,
    t: any,
};

export default withTranslation()(GruposTableMaterial);
